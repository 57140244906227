html,
body {
  margin: 0;
  font-family: "Open Sans";
  background-color: #f5f5f5;
}
header.ant-layout-header.main-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background: linear-gradient(to bottom, #fff, #f5f5f5);
  height: 75px;

  box-shadow: 0 2px 8px #ccc;
  line-height: 0;
  gap: 10px;
}
.logo-text {
  color: #3c41a9;
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 600;
}

main.ant-layout-content.main {
  min-height: 100vh;
  padding: 24px;
}

.ant-list .ant-list-item .ant-list-item-meta {
  align-items: center !important;
  padding: 0 10px;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  margin: 0 0 4px 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  line-height: 1.5714285714285714;
}

li.ant-list-item:nth-child(odd) {
  background: #f7f7f7;
}

.no-data {
  margin-top: 50;
  padding: 24px;
}
